<template>
  <div class="wallet-deposit">
    <div class="container-fluid mt--5">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0">Deposit</h3>
        </div>
        <div class="card-body">
          <el-tabs type="card" v-model="activeTab">
            <el-tab-pane label="Debit/Credit Card" name="card">
              <deposit-card/>
            </el-tab-pane>
            <el-tab-pane label="Bank Transfer" name="bank">
              <deposit-bank/>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepositBank from './components/DepositBank.vue';
import DepositCard from './components/DepositCard.vue';

export default {
  components: { DepositBank, DepositCard },
  data(){
    return {
      activeTab: "card"
    }
  }
};
</script>

<style>
</style>