<template>
  <el-popover
    popper-class="wallet-select"
    placement="bottom"
    trigger="click"
    width="400"
    v-model="visible"
    @show="show"
  >
    <div class="el-scrollbar">
      <div
        class="
          el-select-dropdown__wrap
          el-scrollbar__wrap el-scrollbar__wrap--hidden-default
        "
      >
        <ul class="el-scrollbar__view el-select-dropdown__list">
          <li
            class="el-select-dropdown__item border-bottom pt-2 pb-2 h-100"
            v-for="wallet of walletLists"
            :key="wallet.ledger_id"
            @click="selecteItem(wallet)"
          >
            <wallet-select-item :wallet-data="wallet" />
          </li>
        </ul>
      </div>
    </div>
    <div slot="reference" class="el-input cursor-pointer" v-loading="loading">
      <div class="el-input__inner wallet-active">
        <wallet-select-item
          v-if="walletSelect"
          ref="walletSelectActive"
          :wallet-data.sync="walletSelect"
        />
      </div>
      <span class="el-input__suffix"
        ><span class="el-input__suffix-inner"
          ><i
            :class="`el-select__caret el-input__icon ${
              visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
            }`"
          ></i></span
      ></span>
    </div>
  </el-popover>
</template>

<script>
import WalletApi from "@/api/wallet/wallet";
import WalletSelectItem from "./WalletSelectItem.vue";
export default {
  name: "WalletSelect",
  props: {
    ignoreCurrency: {
      type: String,
      default: "",
    },
  },

  components: {
    WalletSelectItem,
  },
  data() {
    return {
      loading: false,
      walletLists: [],
      walletSelect: null,
      visible: false,
    };
  },
  methods: {
    getWallet(activeCurrency = "") {
      this.loading = true;
      this.walletLists = [];
      WalletApi.getWalletList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          data.forEach((item) => {
            if (item.currency != this.ignoreCurrency) {
              this.walletLists.push(item);
            }
          });
          if(activeCurrency){
            this.selectActive(activeCurrency);
          }
          
        }
      });
    },
    selecteItem(wallet) {
      this.walletSelect = wallet;
      this.visible = false;
      if (this.$refs.walletSelectActive)
        this.$refs.walletSelectActive.getBalance(wallet.bankholderID);
      this.$emit("onChange", wallet);
    },
    selectActive(activeCurrency) {
      this.walletLists.forEach((item) => {
        if (item.currency.toUpperCase() == activeCurrency.toUpperCase()) {
          this.selecteItem(item);
        }
      });
    },
    show(activeCurrency = "") {
      this.getWallet(activeCurrency);
    },
    reset() { 
      this.walletSelect = null
    }
  },
  mounted() { 
    this.$emit("onLoaded");
  }

};
</script>

<style lang="scss">
.wallet-select {
  .el-select-dropdown__item {
    height: auto;
  }
}
.wallet-active {
  padding-left: 5px;
  padding-top: 13px;
  padding-right: 30px;
  line-height: 34px;
  min-height: 60px;
}
</style>