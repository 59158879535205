<template>
  <el-dialog
    title="Upload slip"
    :visible.sync="dialogUploadSlip"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <div class="wallet-upload-slip mt--4">
      <div
        class="dropzone-container mb-4"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        v-if="files.length == 0"
      >
        <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onSelected"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
        />

        <label for="fileInput" class="file-label">
          <div v-if="isDragging">Release to drop files here.</div>
          <div v-else>
            Drop your PNG or JPEG file<br />
            or <u>click here</u> to upload.
          </div>
        </label>
      </div>

      <!-- Note: Only add the code block below -->
      <div class="preview-container text-center" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <el-image :src="generateURL(file)" fit="cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-document"></i>
            </div>
          </el-image>
          <p class="file-info">
            {{ file.name }} <br />
            File size {{ Math.round(file.size / 1000) + "kb" }}
          </p>
          <div>
            <button
              class="ml-2 btn btn-danger btn-sm"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>×</b>
            </button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogUploadSlip = false">Cancel</el-button>
      <el-button type="primary" @click="uploadToServer()" 
      :loading="uploading"
      :disabled="uploading"
        >Upload</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Auth from "@/api/auth";
import axios from 'axios'
export default {
  data() {
    return {
      dialogUploadSlip: false,
      wallet: null,
      uploading: false,
      isDragging: false,
      files: [],
      fileAccept: ["application/pdf", "image/jpeg", "image/png"],
    };
  },
  methods: {
    open(wallet) {
      this.wallet = wallet;
      this.dialogUploadSlip = true;
    },
    close(){
      this.wallet = null;
      this.dialogUploadSlip = false;
      this.files = [];
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    onSelected() {
      this.checkFile(this.$refs.file.files);
    },
    drop(e) {
      e.preventDefault();
      this.checkFile(e.dataTransfer.files);
      this.isDragging = false;
    },
    checkFile(files) {
      for (let i = 0; i < files.length; i++) { 
          if (this.fileAccept.includes(files[i].type)) {
            this.files.push(files[i]);
          }
      }
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },

    uploadToServer() {
      if(this.files.length == 0){
        this.$swal("Message", "Please select file on your device", "error");
        return false;
      }
      this.uploading = true;
      const files = this.files;
      const formData = new FormData();
      formData.append("currency", this.wallet.currency);
      formData.append("walletId",this.wallet.bankholderID);
      files.forEach((file) => {
        formData.append("slipfile", file);
      });
      let url = `${process.env.VUE_APP_SERVER_BASE_API}/api/services/v1/wallet/slip`;
      axios({
        method: "POST",
        url: url,
        data: formData,
        headers: {
          authorization: Auth.getToken(),
        },
      })
        .then((response) => {
          this.uploading = false;
          if (response.data.result) {
            this.$swal(`Message`, `Request Topup by slip now complete. You balance will be updated within  2 hours`, "success").then(
              () => {
                this.close()
              }
            );
          } else {
            this.$swal("Message", response.data.message, "error");
          }
        })
        .catch((err) => {
          this.uploading = false;
          if(err.response){
                this.$swal("Message", err.response.data.message, "error");
              }
        });
    },
  },
};
</script>

<style lang="scss">
.wallet-upload-slip {
  .dropzone-container {
    padding: 1rem;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
    text-align: center;
    min-height: 150px;
  }

  .hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .file-label {
    font-size: 14px;
    display: block;
    cursor: pointer;
  }

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    * {
      flex: 1 1 160px;
    }
  }
  .file-info {
    font-size: 12px;
  }
  .image-slot {
    font-size: 3rem;
  }

  .preview-card {
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
  }
}
</style>