<template>
  <div class="wallet-deposit-card">
    <el-steps :active="active" align-center>
      <el-step title="Account info"></el-step>
      <el-step title="Payment"></el-step>
      <el-step title="Confirm"></el-step>
    </el-steps>
    <div class="row justify-content-md-center mt-4">
      <div class="col-12 col-xl-10" v-show="active == 1">
        <div class="card mb-3">
          <div class="card-body" v-loading="loading">
            <el-form ref="cardForm" :model="cardModel" :rules="cardRules">
              <div class="row">
                <div class="col-lg-6">
                  <el-form-item label="Account">
                    <wallet-select ref="walletTo" @onChange="selectWallet" />
                  </el-form-item>
                  <el-form-item label="Amount" prop="amount">
                    <el-input
                      class="big-input"
                      type="number"
                      placeholder="Please enter amount"
                      v-model="cardModel.amount"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6">
                  <div class="mb-4">
                    <label class="el-form-item__label float-none"
                      >My Card</label
                    >
                    <div
                      class="radio-card position-relative"
                      v-for="card of cardStore"
                      :key="card.ref"
                    >
                      <el-radio
                        v-model="cardStoreSelect"
                        :label="card.ref"
                        @change="changeCard(card)"
                        border
                        class="custom"
                      >
                      <svg-icon :icon-class="getPaymentLogo(card.cardNetwork)" />
                        <!-- <span
                          :class="`fi fi-${card.countryCode.toLowerCase()} mr-1`"
                        >
                        </span>
                        <span class="mr-1"
                          >{{ card.cardCurrency }}-{{ card.cardType }}</span
                        > -->
                        <span><b>**** **** **** {{ card.last3digi }}</b></span>
                        
                        <div class="pl-4 text-xs">
                          <span class="mr-1"
                          >{{ card.cardCurrency }}-{{ card.cardType }}</span
                        >
                          <span>{{ card.expireDate }}</span>
                        </div>
                      </el-radio>
                      <div class="btn-delete position-absolute top-0 right-0">
                        <el-button
                          class="p-1"
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="onDeleteCard(card)"
                        ></el-button>
                      </div>
                    </div>
                    <div>
                      <el-radio
                        v-model="cardStoreSelect"
                        @change="clearCard"
                        label=""
                        border
                        >New Card</el-radio
                      >
                    </div>
                  </div>

                  <div v-if="!cardStoreSelect">
                    <el-form-item label="Card Number" prop="cardnumber">
                      <input-mask
                        class="el-input__inner"
                        v-model="cardModel.cardnumber"
                        mask="9999-9999-9999-9999"
                      ></input-mask>
                    </el-form-item>
                    <div class="d-flex" style="grid-gap: 2%">
                      <el-form-item label="Expired Date" prop="expire">
                        <input-mask
                          ref="expiredate"
                          class="el-input__inner"
                          v-model="cardModel.expire"
                          mask="99/99"
                        ></input-mask>
                      </el-form-item>
                      <el-form-item label="CVV" prop="securitycode">
                        <el-input
                          ref="securitycode"
                          v-model="cardModel.securitycode"
                          show-password
                          maxlength="3"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="Remember card?">
                        <el-checkbox
                          v-model="remembercard"
                          label="Remember"
                          border
                        ></el-checkbox>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
            <div class="text-right">
              <el-button
                round
                type="primary"
                :loading="submittingCard"
                :disabled="submittingCard"
                @click="continuePreview()"
                >Continue</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-8" v-show="active == 2">
        <div class="card mb-3 card-wallet">
          <div class="card-header p-1">
            <h4 class="mb-0">Deposit to</h4>
          </div>
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                My Account
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ calcModel.to }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Topup Amount
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ cardModel.amount }} {{ calcModel.to }}
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3 card-wallet">
          <div class="card-header p-1">
            <h4 class="mb-0">From Card</h4>
            <div class="position-absolute top-2 right-4">
              <h1>
                <span :class="`fi fi-${cardInfo.code.toLowerCase()}`"> </span>
              </h1>
            </div>
          </div>
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Card Country
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ cardInfo.name }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Card Number
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ cardModel.cardnumber }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Expired
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ cardModel.expire }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Card Type
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ cardInfo.type.toUpperCase() }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Pay
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ rateInfo.topup.toFixed(2) }} {{ calcModel.from }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Surcharge
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ rateInfo.surcharge.toFixed(2) }} {{ calcModel.from }}
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Total pay
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                {{ rateInfo.total.toFixed(2) }} {{ calcModel.from }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!topupServiceReady"
          class="card mb-3 text-center alert alert-danger"
        >
          Card topup service is unavailable please contact AB plus team or do
          bank topup service instead
        </div>

        <div class="d-flex justify-content-between p-4">
          <el-button round @click="back()">Back</el-button>
          <el-button
            v-if="topupServiceReady"
            round
            type="primary"
            :loading="submittingPayment"
            :disabled="submittingPayment"
            @click="confirm()"
            >Confirm</el-button
          >
        </div>
      </div>

      <div class="col-12 col-xl-8" v-show="active == 3">
        <div class="card mb-3">
          <div class="card-body min-vh-25">
            <div class="text-center center-screen">
              <div>
                <div id="st-notification-frame"></div>
                <form
                  id="st-form"
                  name="form"
                  :action="actionPayment"
                  method="POST"
                ></form>
                <div class="center-screen text-center min-vh-25">
                  <div v-loading="paymentProcess"></div>
                  <h4 v-if="paymentProcess">Processing... Please wait</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalletSelect from "@/views/Wallet/components/WalletSelect/WalletSelect.vue";
import axios from "axios";
import Utils from "@/utils/";
import WalletApi from "@/api/wallet/wallet";
import UtilsApi from "@/api/utility";
import countryCurrency from "@/utils/map-country-currency.json";
export default {
  components: {
    WalletSelect,
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    membercode() {
      return this.$store.getters.membercode;
    },
  },
  data() {
    var validateExpireCard = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter expire card"));
      } else {
        let now = new Date();
        let end = new Date();
        let expiredate = value.split("/");
        let month = parseInt(expiredate[0]);
        let year = parseInt(`20${expiredate[1]}`);
        end.setFullYear(year, month, 0);
        if (month > 12) {
          callback(new Error("Please enter a valid expiration date"));
        } else if (end < now) {
          callback(new Error("Please enter a valid expiration date"));
        } else {
          callback();
        }
      }
    };

    return {
      active: 1,
      submittingCard: false,
      submittingPayment: false,
      paymentProcess: false,
      loading: false,
      topupServiceReady: true,
      remembercard: false,
      actionPayment:
        "https://payments.abmoneyplus.com/order/payments/moto/app-payment-finish.php",
      cardStore: [],
      cardStoreSelect: "",
      cardModel: {
        cardnumber: "",
        expire: "",
        securitycode: "",
        amount: "",
      },
      topupService: {
        currency: "",
        amount: 0,
        bankholderId: "",
      },
      jwt: "",
      cardRules: {
        cardnumber: [
          {
            required: true,
            message: "Please enter card number",
            trigger: "blur",
          },
        ],
        expire: [
          {
            required: true,
            validator: validateExpireCard,
            trigger: "blur",
          },
        ],
        securitycode: [
          {
            required: true,
            message: "Please enter CVV",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "Please enter amount",
            trigger: "blur",
          },
        ],
      },
      calcModel: {
        customertype: "normal",
        customeruid: "",
        from: "",
        to: "",
        pay: 0,
        destinationreceive: 0,
        cardtype: "debit",
        feetype: "normal",
        fx: false,
      },
      cardInfo: {
        code: "",
        flag: "",
        name: "",
        currency: "",
        type: "",
      },
      processModel: {
        total: "",
        cardnumber: "",
        expiryyear: "",
        expirymonth: "",
        securitycode: "",
        clientcode: "",
        remembercard: "",
        currency: "",
      },
      topupLogData: {
        baseamount: "",
        currencyiso3a: "",
        enrolled: "",
        errorcode: "",
        errormessage: "",
        orderreference: "",
        settlestatus: "",
        status: "",
        transactionreference: "",
        action: "wallettopup",
        bankholderid: "",
        currency: "",
        feepay: "",
        plusuid: "",
        rate: "",
        topup: "",
        total: "",
        routerrate: "",
        surcharge: "",
        countryCode: "",
        cardType: "",
        cardCurrency: "",
        rememberCard: false,
        last3digi: "",
        cardNetwork: "",
      },
      rateInfo: {
        currency: "",
        topup: 0,
        rate: 0,
        feepay: 0,
        total: 0,
        margin: 0,
        routerrate: 0,
        surcharge: 0,
      },
      
    };
  },

  methods: {
    next() {
      if (this.active < 3) {
        this.active++;
      }
    },
    back() {
      if (this.active > 1) {
        this.active--;
      }
    },
    getPaymentLogo(card) { 
      return Utils.getPaymentLogo(card);
    },
    getCardStore() {
      this.cardStore = [];
      this.loading = true;
      WalletApi.cardList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.cardStore = data;
        }
      });
    },

    selectCountryName(code) {
      let result = Utils.findObject(countryCurrency, code, "countryCode");

      return result ? result.country : code;
    },

    continuePreview() {
      if (!this.calcModel.from) {
        this.$swal("Message", "Pease select account topup", "error");
        return false;
      }
      if (this.cardStoreSelect) {
        this.continueWithMyCard();
      } else {
        this.continueWithNewCard();
      }
    },

    continueWithMyCard() {
      let myCard = Utils.findObject(this.cardStore, this.cardStoreSelect, "ref");
      this.cardModel.expire = myCard.expireDate
       this.calcModel.cardtype = this.checkCreditCard(
        myCard.cardType.toLowerCase(),
        myCard.countryCode,
        this.calcModel.to
      )
      this.calcSurchage((calcData) => {
        if (calcData) {
          this.setRateInfo(calcData);
          this.checkWalletReady();
          this.next();
        }
      });
    },
    continueWithNewCard() {
      this.$refs.cardForm.validate((valid) => {
        if (valid) {
          this.cardBinCheck(
            this.cardModel.cardnumber.replaceAll("-", ""),
            (data) => {
              if (data) {
                this.setCardInfo(data);
                this.calcSurchage((calcData) => {
                  if (calcData) {
                    this.setRateInfo(calcData);
                    this.checkWalletReady();
                    this.next();
                  }
                });
              }
            }
          );
        }
      });
    },

    changeCard(card) {
      this.cardModel.cardnumber = `**** **** **** ${card.last3digi}`;
      this.cardInfo.type = card.cardType;
      this.cardInfo.name = this.selectCountryName(card.countryCode);
      this.cardInfo.code = card.countryCode;
      this.cardInfo.currency = card.cardCurrency;
    },

    clearCard() {
      this.cardModel.cardnumber = "";
      this.cardInfo.type = "";
      this.cardInfo.name = "";
      this.cardInfo.code = "";
      this.cardInfo.currency = "";
    },

    deleteCard(card) {
      this.loading = true;
      WalletApi.cardRemove(card.ref).then(({ result, message }) => {
        this.loading = false;
        if (result) {
          this.getCardStore();
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },

    onDeleteCard(card) {
      this.$swal({
        title: "Delete Card",
        html: `This will delete card <b>**** **** **** ${card.last3digi}</b>. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Comfirm",
        cancelButtonText: "Cancel",
        showCloseButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteCard(card);
        } else {
          this.$message(
            `Remove card **** **** **** ${card.last3digi} Canceled`
          );
        }
      });
    },

    setCardInfo(data) {
      if (data["country"]) { 
        if (data["type"]) {
          this.calcModel.cardtype = this.checkCreditCard(
            data["type"],
            data["country"]["alpha2"],
            this.calcModel.to
          );
          this.cardInfo.type = data["type"] == "debit" ? "Debit" : "Credit";
        } else {
          this.calcModel.cardtype = this.checkCreditCard(
            data["type"],
            data["country"]["alpha2"],
            this.calcModel.to
          );
          this.cardInfo.type = "Debit";
        }
        this.cardInfo.code = data["country"]["alpha2"];
        this.cardInfo.flag = data["country"]["emoji"];
        this.cardInfo.name = data["country"]["name"];
        this.cardInfo.currency = data["country"]["currency"];
      } else {
        this.calcModel.cardtype = "Credit";
        this.cardInfo.type = "Credit"
        this.cardInfo.code = "DE";
        this.cardInfo.flag = "DE";
        this.cardInfo.name = "Germany";
        this.cardInfo.currency = "EUR";
      }
      
      this.topupLogData.cardNetwork = data['scheme']
    },

    setRateInfo(data) {
      this.rateInfo.currency = `${this.calcModel.from}${this.calcModel.to}`;
      this.rateInfo.rate = parseFloat(data["rate"]["normal"]);
      this.rateInfo.feepay = parseFloat(data["feelist"][3]["feevalue"]);

      let pay = parseFloat(data["pay"]["value"]);
      this.rateInfo.total = pay + this.rateInfo.feepay;
      this.rateInfo.topup = pay;
      this.rateInfo.routerrate = data["routerrate"] ?? 0;
      this.rateInfo.margin = data["margin"] ?? 0;
      this.rateInfo.surcharge = this.rateInfo.feepay;
      this.topupLogData.currency = this.rateInfo.currency;
      this.topupLogData.feepay = this.rateInfo.feepay;
      this.topupLogData.rate = this.rateInfo.rate;
      this.topupLogData.topup = this.rateInfo.topup;
      this.topupLogData.total = this.rateInfo.total;
      this.topupLogData.routerrate = this.rateInfo.routerrate;
      this.topupLogData.surcharge = this.rateInfo.surcharge;
    },

    cardBinCheck(number, cb) {
      this.submittingCard = true;
      UtilsApi.binLookup(number.substring(0, 8)).then(
        ({result, data, message}) => {
          this.submittingCard = false;
          if (result) {
           cb(data);
          } else {
            cb(null);
            this.$swal("Message", message, "error");
          }
        });
    },

    calcSurchage(cb) {
      const host =
        process.env.NODE_ENV === "development"
          ? "https://services-stage.ab-fx.com"
          : "https://services.ab-fx.com";
      // const url = host + "/order/api/app/calculator.php";
      const url = host + "/order/api/app/calculatorlive.php";

      if (this.cardInfo.currency.toUpperCase() === 'GBP') {
         this.calcModel.from = "GBP"
      } else if (this.cardInfo.currency.toUpperCase() === 'EUR') {
         this.calcModel.from = "EUR"
      } else { 
        this.calcModel.from = "GBP"
      }

      this.calcModel.destinationreceive = parseFloat(this.cardModel.amount);
      this.calcModel.pay = 0;
      this.calcModel.fx = false;

      this.calcModel.customeruid = this.plusuid;

      // if (!["GBP", "EUR"].includes(this.calcModel.to)) {
      //   this.calcModel.destinationreceive = parseFloat(this.cardModel.amount);
      //   this.calcModel.pay = 0;
      //   this.calcModel.fx = true;
      // } else {
      //   this.calcModel.destinationreceive = 0;
      //   this.calcModel.pay = parseFloat(this.cardModel.amount);
      //   this.calcModel.fx = false;
      // }

      const formCalc = {
        uid: Utils.uuid(),
        lang: "en",
        device: "browser",
        apiversion: 0.1,
        submit: this.calcModel,
      };

      const option = {
        method: "POST",
        data: JSON.stringify(formCalc),
        url,
      };
      this.submittingCard = true;
      axios(option).then(
        (response) => {
          this.submittingCard = false;
          if (response.status == 200) {
            var data = response.data;
            cb(data);
          } else {
            cb(null);
          }
        },
        (err) => {
          this.submittingCard = false;
          cb(null);
        }
      );
    },

    checkCreditCard(type, country, currency) {
      let cardType = type;
      if (currency == "GBP" && country == "GB") {
        return cardType;
      }
      if (currency == "EUR" && this.checkSepa(country)) {
        return cardType;
      }
      return "credit";
    },
    checkSepa(country) {
      let sepa = Utils.findObject(
        Utils.sepaCountries,
        country.toUpperCase(),
        "code"
      );
      if (!sepa) {
        return false;
      } else {
        return true;
      }
    },

    selectWallet(wallet) {
      this.calcModel.from = wallet.currency == "EUR" ? "EUR" : "GBP";
      this.calcModel.to = wallet.currency;
      this.topupLogData.bankholderid = wallet.bankholderID;
    },

    getJsonWebTokenPayment() {
      const pathState =
        process.env.NODE_ENV === "development"
          ? "app-payment-process-test.php"
          : "app-payment-process.php";
      let url = `https://payments.abmoneyplus.com/order/payments/moto/${pathState}`;
      let clientcode = `TP-${this.membercode}-${
        this.topupLogData.bankholderid
      }-${Utils.getRandomString(4)}`;

      var cardnumber = this.cardModel.cardnumber.replaceAll("-", "");
      var expire = this.cardModel.expire.split("/");

      this.processModel.total = this.rateInfo.total;
      this.processModel.cardnumber = cardnumber;
      this.processModel.expiryyear = expire[1];
      this.processModel.expirymonth = expire[0];
      this.processModel.securitycode = this.cardModel.securitycode;
      this.processModel.clientcode = clientcode;
      this.processModel.remembercard = this.remembercard ? "1" : "0";
      this.processModel.currency = this.calcModel.from;

      this.topupLogData.plusuid = this.plusuid;
      this.topupLogData.countryCode = this.cardInfo.code;
      this.topupLogData.cardType = this.cardInfo.type;
      this.topupLogData.cardCurrency = this.cardInfo.currency;
      this.topupLogData.rememberCard = this.remembercard;
      this.topupLogData.expireDate = this.cardModel.expire;
      this.topupLogData.last3digi = cardnumber.substr(cardnumber.length - 4);

      var formData = JSON.parse(JSON.stringify(this.processModel)); //clone object

      if (this.cardStoreSelect) {
        delete formData.cardnumber;
        delete formData.expiryyear;
        delete formData.expirymonth;
        delete formData.securitycode;
        delete formData.remembercard;

        formData.referencetoken = this.cardStoreSelect;
      }

      var bodyFormData = new FormData();

      Object.keys(formData).forEach((key) => {
        bodyFormData.append(key, formData[key]);
      });

      const option = {
        url: url,
        method: "POST",
        data: bodyFormData,
      };
      this.submittingPayment = true;
      axios(option).then(
        (response) => {
          this.submittingPayment = false;
          let res = response.data;
          if (res.result) {
            this.jwt = res.data;

            // console.log(this.topupData)
            this.next();
            this.tradingRun();
          } else {
            this.submittingPayment = false;
          }
        },
        (err) => {
          this.submittingPayment = false;
        }
      );
    },

    confirm() {
      this.getJsonWebTokenPayment();
    },

    savePaymentLog(data) {
      this.topupLogData.baseamount = data["baseamount"];
      this.topupLogData.currencyiso3a = data["currencyiso3a"];
      this.topupLogData.enrolled = data["enrolled"];
      this.topupLogData.errorcode = data["errorcode"];
      this.topupLogData.errormessage = data["errormessage"];
      this.topupLogData.orderreference = data["orderreference"];
      this.topupLogData.settlestatus = data["settlestatus"];
      this.topupLogData.status = data["status"];
      this.topupLogData.transactionreference = data["transactionreference"];

      const option = {
        method: "POST",
        data: JSON.stringify(this.topupLogData),
        url: this.actionPayment,
      };

      axios(option).then(({ data }) => {
        this.active = 1;
        if (
          this.topupLogData.errorcode == "0" &&
          this.topupLogData.errormessage
        ) {
          this.$swal(
            `Payment`,
            `has been successfully processed`,
            "success"
          ).then(() => {
            this.$router.push({ path: "/wallet/history?tab=topup" });
          });
        } else {
          this.$swal(
            "Code:" + this.topupLogData.errorcode,
            this.topupLogData.errormessage,
            "error"
          );
        }
      });
    },

    tradingRun() {
      this.paymentProcess = true;
      var st = SecureTrading({
        livestatus: process.env.NODE_ENV == "production" ? 1 : 0,
        jwt: this.jwt,
        submitOnError: false,
        submitOnCancel: false,
        submitOnSuccess: false,
        submitCallback: async (data) => {
          this.loading = false;
          this.savePaymentLog(data);
        },
      });

      st.Components({ startOnLoad: true });

      st.on("success", async () => {
        this.paymentProcess = false;
        this.active = 1;
      });

      st.on("error", async () => {
        this.paymentProcess = false;
        this.active = 1;
      });
    },
    injectString() {
      if (document.getElementById("secure-trading")) return; // was already loaded
      var scriptTag = document.createElement("script");
      scriptTag.src = "https://webservices.securetrading.net/js/v3/st.js";
      scriptTag.id = "secure-trading";
      document.getElementsByTagName("body")[0].appendChild(scriptTag);
    },
    checkWalletReady() {
      this.submittingCard = true;
      this.topupService.currency = this.calcModel.to;
      this.topupService.bankholderId = this.topupLogData.bankholderid;
      this.topupService.amount = this.topupLogData.topup;
      WalletApi.checkWalletReady(this.topupService).then(
        ({ result, data, message }) => {
          this.submittingCard = false;
          if (result) {
            this.topupServiceReady = data.statusready;
          } else {
            this.topupServiceReady = false;
            this.$swal("Message", message, "error");
          }
        }
      );
    },
  },
  mounted() {
    if (this.$route.query.to) {
      this.$refs.walletTo.show(this.$route.query.to.toUpperCase());
    }

    this.injectString();
    this.getCardStore();
  },
};
</script>

<style lang="scss">
.wallet-deposit-card {
  .el-form-item__label {
    line-height: 10px;
    padding: 0 5px 0 0;
  }

  .wallet-deposit-label {
    font-size: 0.75rem;
  }
  .wallet-deposit-info {
    font-size: 0.85rem;
    font-weight: 700;
  }
  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .el-form-item__content {
    line-height: 39px;
    position: relative;
    font-size: 14px;
  }
  .btn-delete {
    display: none;
  }
  .el-radio.is-bordered.custom {
    height: 65px;
}
  .radio-card {
    &:hover {
      .btn-delete {
        display: block;
      }
    }
  }
  .svg-icon{
    width: 3em;
    vertical-align: middle;
    fill: currentColor;
    line-height: 36;
    overflow: hidden;
    margin-right: 0.5rem;
    font-size: 14px;
  }
}
</style>