<template>
  <div class="wallet-deposit-bank min-vh-25" v-loading="loading">
    <div class="row justify-content-md-center">
      <div v-if="$route.query.to">
        <el-button
          type="text"
          @click="
            filtter('');
            $router.push({ path: '/wallet/deposit?t=' + new Date().getTime() });
          "
        >
          Show more
        </el-button>
      </div>
      <div
        class="col-md-12 col-xl-6"
        v-for="wallet of walletList"
        :key="wallet.ledger_id"
      >
        <div class="card card-wallet pr-2">
          <div class="card-header p-0">
            <h4 class="pb-0 pt-2">
              <el-image
                style="width: 40px; height: auto; vertical-align: middle"
                :src="`/img/currency-flags/${wallet.currency.toLowerCase()}.png`"
                fit="contain"
              ></el-image
              ><span class="ml-1">{{ wallet.currency.toUpperCase() }}</span>
            </h4>
            <div
              v-if="!['GBP', 'EUR'].includes(wallet.currency.toUpperCase())"
              class="position-absolute top-2 right-2 pt-0 pr-2"
            >
              <el-button
                type="success"
                icon="el-icon-upload"
                round
                size="small"
                @click="uploadSlip(wallet)"
              >
                Upload slip
              </el-button>
            </div>
          </div>

          <div class="card-body p-0 pt-2 pb-2">
            <div v-if="upgradePlus" class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Account name
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                <el-input size="mini" :value="wallet.accountname"> </el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Copy Account name"
                  placement="right"
                  transition=""
                >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-copy-document"
                    @click="copyText(wallet.accountname)"
                  ></el-button
                ></el-tooltip>

                <!-- {{ wallet.accountname }}
                <el-button
                  class="p-1 pl-2"
                  type="text"
                  @click="copyText(wallet.accountname)"
                  >copy</el-button
                > -->
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                {{ getLabelAccountNumber(wallet.currency) }}
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                <el-input size="mini" :value="getAccount(wallet)"> </el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="`Copy ${getLabelAccountNumber(wallet.currency)} `"
                  placement="right"
                  transition=""
                >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-copy-document"
                    @click="copyText(wallet.domestic_account_display ? wallet.domestic_accountnumber : wallet.account)"
                  ></el-button
                ></el-tooltip>
                <!-- {{ wallet.account }}
                <el-button
                  class="p-1 pl-2"
                  type="text"
                  @click="copyText(wallet.account)"
                  >copy</el-button
                > -->
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                {{ getLabelSortCode(wallet.currency) }}
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                <el-input size="mini" :value="wallet.domestic_account_display && wallet.type === 'virtual' ? wallet.domestic_sortcode : wallet.sortcode"> </el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="`Copy ${getLabelSortCode(wallet.currency)} `"
                  placement="right"
                  transition=""
                >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-copy-document"
                    @click="copyText(wallet.domestic_account_display && wallet.type === 'virtual' ? wallet.domestic_sortcode : wallet.sortcode)"
                  ></el-button
                ></el-tooltip>
                <!-- {{ wallet.sortcode }}
                <el-button
                  class="p-1 pl-2"
                  type="text"
                  @click="copyText(wallet.sortcode)"
                  >copy</el-button
                > -->
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center wallet-deposit-label">
                Reference
              </div>
              <div class="d-flex align-items-center wallet-deposit-info">
                <el-input size="mini" :value="getReference(wallet.bankholderID)"> </el-input>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Copy Reference "
                  placement="right"
                 transition=""
                >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    icon="el-icon-copy-document"
                    @click="copyText(getReference(wallet.bankholderID))"
                  ></el-button
                ></el-tooltip>
                <!-- {{ getReference(wallet.bankholderID) }}
                <el-button
                  class="p-1 pl-2"
                  type="text"
                  @click="copyText(getReference(wallet.bankholderID))"
                  >copy</el-button
                > -->
              </div>
            </div>
          </div>
          <div class="card-footer p-0 pt-1 pb-1 text-center">
            <span
              class="text-xs"
              v-if="['GBP', 'EUR'].includes(wallet.currency.toUpperCase())"
            >
              Please deposit fund into your wallet to above account. Then put
              the reference for allow system able to allocate fund automatically
            </span>
            <span class="text-xs" v-else>
              Please deposit fund into your wallet to above account. Then upload
              slip proof of payment for allow our team able to allocate fund
            </span>
          </div>
        </div>
      </div>
    </div>
    <upload-slip ref="uploadSlip" />
  </div>
</template>

<script>
import WalletApi from "@/api/wallet/wallet";
import UploadSlip from "./UploadSlip.vue";
import Utils from "@/utils/";
export default {
  components: { UploadSlip },
  computed: {
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      loading: false,
      walletList: [],
      walletListItem: [],
    };
  },
  methods: {
    getWalletActive() {
      this.loading = true;
      let walletGbpEur = [];
      let walletOther = [];
      WalletApi.getCurrencyActiveList().then(({ result, data }) => {
        this.loading = false;
        if (result && Array.isArray(data)) {
          data.forEach((item) => {
            if (item.bankholderID) {
              if (["GBP", "EUR"].includes(item.currency.toUpperCase())) {
                walletGbpEur.push(item);
              } else {
                walletOther.push(item);
              }
            }
          });
          this.walletListItem = walletGbpEur.concat(walletOther);

          this.filtter(this.$route.query.to);
        }
      });
    },

    filtter(currency = "") {
      if (currency) {
        let account = Utils.findObjectArray(
          this.walletListItem,
          currency.toLowerCase(),
          "currency"
        );
        this.walletList = account ? account : [];
      } else {
        this.walletList = this.walletListItem;
      }
    },
    getLabelAccountNumber(currency) {
      return ["GBP", "THB"].includes(currency.toUpperCase())
        ? "Account Number"
        : "IBAN";
    },
    getLabelSortCode(currency) {
      return currency.toUpperCase() == "GBP" ? "Sort code" : "BIC";
    },
    getReference(bankholderid) {
      return `TP${Number(bankholderid.substring(bankholderid.length - 6))}`;
    },
    uploadSlip(wallet) {
      this.$refs.uploadSlip.open(wallet);
    },
    getAccount(wallet) {
      return wallet.domestic_account_display && wallet.type === 'virtual' ? wallet.domestic_accountnumber :  wallet.account
    },
    getSortcode(wallet) {
      return wallet.domestic_account_display && wallet.type === 'virtual' ? wallet.domestic_sortcode :  wallet.sortcode
    },
    copyText(text) {
      var cpText = text.replace(/[^\a-\z\A-\Z0-9 ]/g, "");
      const el = document.createElement('textarea');
      el.value = cpText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$message({
        message: "Copied: " + cpText,
        type: "success",
      });
    },
  },
  mounted() {
    this.getWalletActive();
  },
};
</script>

<style lang="scss">
.wallet-deposit-bank {
  .wallet-deposit-label {
    font-size: 0.75rem;
  }
  .wallet-deposit-info {
    font-size: 0.85rem;
    font-weight: 700;
  }
}
</style>