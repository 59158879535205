<template>
  <div class="wallet-item-select" style="width: 100%">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <el-image
          style="width: 48px;"
          :src="`/img/currency-flags/${walletData.currency.toLowerCase()}.png`"
          fit="contain"
        ></el-image>
        <div style="margin-left:8px;">
          <div v-if="upgradePlus" class="wallet-account-number">{{walletData.bankholderID}}</div>
          <div class="wallet-name">My {{walletData.currency}} Account</div>
        </div>
    
      </div>
      <div class="d-flex align-items-center">
        <div class="text-right">
          <div class="p-0 text-xs" style="line-height: 10px">
            Balance
          </div>
          <div class="p-0" style="line-height: 20px">
            <span v-if="loading"><i class="el-icon-loading"></i></span
            ><span v-if="!loading && balance" class="font-weight-bold">{{ balance.balance }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BankApi from "@/api/bankaccount";
export default {
  name: "walletSelectItem",
  props: {
    walletData: {
      type: Object,
    },
  },
  computed:{
    accessRole() {
      return this.$store.getters.accessrole;
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data() {
    return {
      loading: false,
      balance: null,
    };
  },
  methods: {
    getBalance(bankholderID) {
      this.loading = true;
      BankApi.getAccountBalance(bankholderID).then(({ data }) => {
        this.loading = false;
        this.balance = data;
      });
    },
  },
  mounted() {
    if(this.accessRole.balance) this.getBalance(this.walletData.bankholderID);
  },
};
</script>

<style lang="scss">
.wallet-item-select{
  line-height: 16px;
  .wallet-account-number{
    font-size: 15px;
    font-weight: 700;
  }
  
}
</style>